function createOption(text, value) {
    var option = document.createElement("option");
    option.text = text;
    option.value = value;

    return option;
}

function addressFinder(finder) {
    if (!finder) {
        return;
    }

    const postCodeSearchInput = finder.querySelector('.js-find-address-postcode');
    const findButton = finder.querySelector('.js-find-address-button');
    const addressDropdown = finder.querySelector('.js-find-address-dropdown');
    const addressDropdownWrap = finder.querySelector('.js-find-address-dropdown-wrap');
    let addresses = [];

    if (!postCodeSearchInput || !findButton || !addressDropdown || !addressDropdownWrap) {
        return;
    }

    addressDropdown.addEventListener('change', (e) => {
        e.preventDefault();

        const selectedAddress = addresses[e.target.value];
        const fieldset = e.target.closest('fieldset');
        const houseNameOrNumberInput = fieldset.querySelector('.js-house-name-or-number');
        const addressLine1Input = fieldset.querySelector('.js-address-line-1');
        const addressLine2Input = fieldset.querySelector('.js-address-line-2');
        const cityInput = fieldset.querySelector('.js-address-city');
        const postcodeInput = fieldset.querySelector('.js-address-postcode');
        const countyInput = fieldset.querySelector('.js-address-county');

        const houseNameOrNumber = selectedAddress.building_number.trim().length === 0 ? selectedAddress.building_name : selectedAddress.building_number;
        const hasHouseNameOrNumber = houseNameOrNumber.trim().length !== 0;

        if (houseNameOrNumberInput && hasHouseNameOrNumber) {
            houseNameOrNumberInput.value = houseNameOrNumber;
        }

        if (addressLine1Input) {
            addressLine1Input.value = selectedAddress.thoroughfare;
        }

        if (addressLine2Input) {
            addressLine2Input.value = selectedAddress.line_2;
        }

        if (cityInput) {
            cityInput.value = selectedAddress.town_or_city;
        }

        if (postcodeInput && postCodeSearchInput.value) {
            postcodeInput.value = postCodeSearchInput.value.toUpperCase();
        }

        if (countyInput) {
            countyInput.value = selectedAddress.county;
        }

        const collapse = fieldset.querySelector("[data-bs-target^='#manual']");

        if (collapse && collapse.getAttribute('aria-expanded') === 'false') {
            collapse.dispatchEvent(new Event("click"));
        }
    });

    findButton.addEventListener('click', function (e) {
        e.preventDefault();

        const value = postCodeSearchInput.value;

        if (!value) {
            return;
        }

        fetch(`/Umbraco/Surface/AddressLookup/FindAddress/?postCode=${value}`, {
            method: 'POST',
        })
            .then(response => response.json())
            .then(data => {
                addresses = data;
                addressDropdown.innerHTML = '';

                if (data.length) {
                    addressDropdown.appendChild(createOption('Select an address', ''));

                    data.forEach(function (address, index) {

                        var label = address.line_1;

                        if (address.line_2) {
                            label = address.line_1 + ", " + address.line_2;
                        }

                        var option = createOption(label, index);

                        addressDropdown.appendChild(option);
                    });

                    addressDropdownWrap.style.display = 'block';
                }
                else {
                    addressDropdownWrap.style.display = 'none';
                }
            });
    });
}

export default addressFinder;